import {NBAType, NBATypeSchema} from '@/types/NBAType'
import {useIntl} from 'react-intl'
import {Period} from '@/types/Period'
import {NBAStatus} from '@/types/NBAStatus'
import {TaskType} from '@/types/TaskSchema'
import {Icon} from 'pepsico-ds'
import {Link} from 'react-router-dom'
import {ROUTES} from '@/config/constants'
import {useAppDispatch} from '@/store'
import {openNBADrawer, setActiveNBA} from '@ReduxActions'
import {format} from 'date-fns'
// import {useQueryClient} from '@tanstack/react-query'
// import {getNBAUseCase} from '@UseCases'

interface NBANameCellProps {
  id: string
  name: string
  // onClick: () => void
}
interface PeriodCellProps {
  value?: Period
  locale?: Intl.LocalesArgument
  className?: string
}

interface ReportCellProps {
  value?: string
  tasks: Array<TaskType>
  nbaId: string
  nbaName: string
}

export const NBANameCell = ({id, name}: NBANameCellProps) => {
  // const queryClient = useQueryClient()
  const dispatch = useAppDispatch()
  const {formatMessage} = useIntl()

  // Prefetch function
  // const prefetchNBA = () => {
  //   console.log('prefetching NBA', id)
  //   queryClient.prefetchQuery({
  //     queryKey: ['nbas', id],
  //     queryFn: ({signal}) => getNBAUseCase({id}, signal),
  //     // staleTime: 60 * 1000, // 1 minutes
  //   })
  // }

  function openDrawer() {
    // trigger API call for nba details row.original.id.
    dispatch(setActiveNBA({id}))
    dispatch(
      openNBADrawer({
        selectedPage: 'nbaDetails',
        title: formatMessage({id: 'NBA_BUILDER.NBA_DETAILS_DRAWER_TITLE'}),
      })
    )
    // setIsNBADetailsOpen(true)
  }

  return (
    <div
      data-testid="name-cell"
      data-cy="name-cell"
      onClick={openDrawer}
      // onMouseEnter={prefetchNBA} // Prefetch on hover
      className="font-inter truncate-ellipsis max-w-[250px] cursor-pointer text-sm text-link no-underline hover:text-link-hover"
      title={name}
    >
      {name}
    </div>
  )
}

export const NBADescriptionCell = ({description}: {description: string}) => {
  return (
    <div data-testid="description-cell" className="NBA-table-style">
      {description}
    </div>
  )
}

export const NBATypeCell = ({type = NBATypeSchema.enum.RECURRENT}: {type?: NBAType}) => {
  const {formatMessage} = useIntl()

  return (
    <div data-testid="type-cell" className="journey-table-style">
      {formatMessage({id: `NBA_BUILDER.JOURNEY_TYPE_${type}`})}
    </div>
  )
}

export const DatetimeCell = ({value, locale = []}: {value?: string; locale?: Intl.LocalesArgument}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }

  const strDate = value ? `${new Date(value).toLocaleString(locale, options)}` : '-'
  const formatted = strDate.replace(',', '')
  return (
    <div data-testid="datetime-cell" className="text-sm">
      {formatted}
    </div>
  )
}

export const PeriodCell = ({value, className}: PeriodCellProps) => {
  // const options: Intl.DateTimeFormatOptions = {
  //   year: 'numeric',
  //   month: '2-digit',
  //   day: '2-digit',
  // }

  const start = value?.start ? format(value?.start.split('T')[0], 'MM/dd/yyyy') : ''
  const end = value?.end ? format(value?.end.split('T')[0], 'MM/dd/yyyy') : ''

  // const strDate = start ? `${new Date(start).toLocaleString(locale, options)}` : '-'
  // const endDate = end ? `${new Date(end).toLocaleString(locale, options)}` : '-'
  const formatted = `${start} - ${end}`

  return (
    <div data-testid="date-cell" className={className ?? 'text-sm'}>
      {formatted}
    </div>
  )
}

export const DateCell = ({
  value,
  locale = [],
  className,
}: {
  value?: string
  locale?: Intl.LocalesArgument
  className?: string
}) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }

  const strDate = value ? `${new Date(value).toLocaleString(locale, options)}` : '-'
  const formatted = strDate.replace(',', '')
  return (
    <div data-testid="date-cell" className={className ?? 'text-sm'}>
      {formatted}
    </div>
  )
}

export const CloneCell = ({value}: {value?: string}) => {
  const disabled = value === NBAStatus.paused || value === NBAStatus.draft
  return (
    <Icon
      title="clone NBA"
      disabled={disabled}
      icon={'content_copy'}
      className={disabled ? '' : 'cursor-pointer text-link hover:text-link-hover'}
    />
  )
}

export const ReportCell = ({value, nbaId, nbaName}: ReportCellProps) => {
  //  const isTaskNotManual = !tasks.find(task => task.taskTypeName === 'Manual'),
  //    disabled = value === (NBAStatus.draft || NBAStatus.paused),
  //    hidden = isTaskNotManual
  // const isTaskNotManual = !tasks.find(task => task.taskTypeName === 'Manual')
  const disabled = value === (NBAStatus.draft || NBAStatus.paused)

  return (
    <div className={` flex flex-row justify-center justify-items-center gap-4`}>
      <span className="flex flex-row justify-center justify-items-center gap-1	text-sm	">
        <Icon title="" disabled={disabled} icon={'copy'} /> <span className="mt-[2px]"> Duplicate</span>
      </span>

      {disabled ? (
        <span className="flex flex-row justify-center justify-items-center gap-1	text-sm	">
          <Icon title="" disabled={disabled} icon={'fact_check'} /> <span className="mt-[2px]"> Store Report</span>
        </span>
      ) : (
        <Link
          className="flex flex-row justify-center justify-items-center gap-1	text-sm	"
          to={`${ROUTES.NBA_REPORTS}?nbaId=${nbaId}&nbaName=${nbaName}`}
        >
          <Icon title="NBA store report" icon={'fact_check'} className="text-link hover:text-link-hover" />{' '}
          <span className="mt-[2px] text-primary">Store Report</span>
        </Link>
      )}
    </div>
  )
}
